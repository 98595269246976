import React, {useEffect, useState} from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import CabinetInfo from '../../components/CabinetInfo/CabinetInfo'
import Feedback from '../../components/FeedbackBlock/Feedback'
import Card from '../../components/Card/Card'
import HistoryPoint from '../../components/HistoryPoint/HistoryPoint'
import urls from '../../urls'
import ContragentRequest from '../../api/cabinet/Contragent'
import './CabinetPage.scss'
import {useSelector} from 'react-redux'
import CabinetRequest from '../../api/cabinet/Cabinet'
import Loader from '../../components/UI/Loader/Loader'
import error from '../../functions/error'
import {useNavigate} from 'react-router'
import {requestAPI} from "../../api/requestAPI";
import TaskRequest from "../../api/cabinet/Task";
import {API_CABINET_MODEL} from "../../api/cabinet/const";

const CabinetPage = () => {
    const userData = useSelector(state => state.user_data.userData)
    const contragentRequest = new ContragentRequest()
    const cabinet = new CabinetRequest()
    const [contragentInfo, setContragentInfo] = useState({})
    const [contragentStatus, setContragentStatus] = useState(<Loader/>)
    const [history, setHistory] = useState([])
    const [historyStatus, setHistoryStatus] = useState(false)
    const navigate = useNavigate()
    const request = new TaskRequest() // отсюда использую базовый get без префикса
    const [contract_forms, setContract_forms] = useState([])


    useEffect(() => {

        request.get({method: API_CABINET_MODEL.methods.can_buy_land.url})
            .then(response => {
                if (response.success) {
                    if (response.data.ok) {
                        setContract_forms([
                            urls.buy_land,
                            urls.buy_land_com,
                            urls.market_form,
                            urls.bik_invest_form,
                        ])
                    } else {
                        setContract_forms([
                            urls.buy_land_com,
                            urls.market_form,
                            urls.bik_invest_form,
                        ])
                    }
                } else {
                    console.error(error(response))
                    console.log(error(response))
                }
            })

        if (userData.contragent) {


            contragentRequest.getById(userData.contragent)
                .then(response => {
                    if (response.success) {
                        setContragentInfo(response.data.data)
                    } else {
                        setContragentStatus(error(response))
                    }
                })

            cabinet.history(userData.contragent)
                .then(response => {
                    if (response.success) {
                        setHistoryStatus('Нет заявок')
                        setHistory([...response.data.data.results])
                        // const active_tasks = response.data.data?.results?.filter(item => (item.status.name !== 'Отклонено' && item.type === 'RESIDENTIAL_PLOTS')) || [] // выбираем неотклоненные участки ижс
                        // if (active_tasks.length > 0) {
                        //   setContract_forms([
                        //     urls.buy_land_com,
                        //     urls.market_form,
                        //     urls.bik_invest_form,
                        //   ])
                        // } else {
                        //   setContract_forms([
                        //     urls.buy_land,
                        //     urls.buy_land_com,
                        //     urls.market_form,
                        //     urls.bik_invest_form,
                        //   ])
                        // }
                    } else {
                        setHistoryStatus(error(response))
                    }
                })
        } else {
            navigate(urls.logout.path)
        }
    }, [])


    const uslugi = [
        // urls.build_selfcontrol,
        // urls.snyatie_obremeneniya,
        // urls.snyatie_obremeneniya_v_bank,
        // urls.razdel_imushestva,
        // urls.otchuzhdenie_na_rodstvennika,
        // urls.vozvedenie_fundamenta,
        // urls.prodlenie_stroitelstva,
        // urls.ustanovka_ios,
        urls.obrashenie_k_gendirektoru,
        urls.soobshit_o_korrupcii,
        urls.sell_land
    ]

    const servisy = [
        urls.postroit_dom,
        urls.avarinie_situacii,
        urls.skidki_i_bonusi,
        // urls.calculator // временно не оказываем услугу
    ]

    return (
        <>
            <Header/>
            <div className='content'>
                <div className='container'>
                    <CabinetInfo contragent={contragentInfo} status={contragentStatus}/>
                    <Feedback/>
                    {contract_forms.length > 0 ?
                        <div className='service-container mt-32'>
                            {contract_forms.map((item, idx) => <Card key={idx} {...item} />)}
                        </div> : <Loader/>}

                    <h2 className='cabinet__sub-title mt-32'>Услуги для действующих клиентов</h2>
                    <div className='service-container mt-12'>
                        {uslugi.map((item, idx) => <Card key={idx} {...item} />)}
                    </div>

                    <h2 className='cabinet__sub-title mt-32'>История заявок</h2>
                    <div className='history-container mt-12'>
                        {historyStatus ? history.length > 0 ? history.map((item, idx) => <HistoryPoint key={idx}
                                                                                                       item={item}/>) : 'Пока нет ни одной заявки' :
                            <Loader/>}
                    </div>

                    <h2 className='cabinet__sub-title mt-32'>Сервисы</h2>
                    <div className='service-container mt-12'>
                        {servisy.map((item, idx) => <Card key={idx} {...item} />)}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default CabinetPage