export const API_CABINET_MODEL = {
    entity: 'cabinet',
    url: 'cabinet/',
    methods: {
        send_code: {
            url: 'send-code/'
        },
        login: {
            url: 'login/'
        },
        refresh: {
            url: 'refresh/',
        },
        create_contragent: {
            url: 'create-contragent/'
        },
        update_contragent: {
            url: 'update-contragent/'
        },
        get_contragent: {
            url: 'get-contragent/'
        },
        create_task: {
            url: 'create-task/'
        },
        get_client_task: {
            url: 'get-client-task/'
        },
        preferencial: {
            url: 'map/preferencials/'
        },
        can_buy_land: {
            url: 'can-buy-land/'
        }
    },
}